
export const testinomialsData = [
  {
    comment: 'The classrooms are awesome and I enjoy learning. Teaching techniques used are the best.',
    name: 'Rohit'
  },
  {
    comment: 'The teachers here are the best. It is here that I received the most needed assistance.',
    name: 'Rahul'
  },
  {
    comment: 'Learning was never so exciting. Proud to be a part of such an awesome coaching class.',
    name: 'Manisha'
  }
];