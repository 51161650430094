export const menuLinksData = [
  {
    display: 'Home',
    path: '/'
  },
  {
    display: 'About',
    path: '/about'
  },
  {
    display: 'Services',
    path: '/services'
  },
  /*{
    display: 'Faculty',
    path: '/faculty'
  },
  {
    display: 'Gallery',
    path: '/gallery'
  },
  {
    display: 'Resources',
    path: '/resources'
  },*/
  {
    display: 'Contact',
    path: '/contact'
  }
]